// src/components/Login.js

import { Link } from "react-router-dom";

function Login(props) {
  const {
    handleLogin,
    email,
    userError,
    password,
    setEmail,
    setPassword,
  } = props;
 return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h2 className="text-3xl font-bold mb-4">Login</h2>
      {userError && <div className="text-red-500 mb-4">{userError}</div>}
      <form onSubmit={handleLogin} className="w-full max-w-sm">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Login
        </button>
        <div>
          <span className="mr-2">Don't have an account?</span>
          <Link
            to="/signup"
            className="text-blue-500 hover:underline focus:outline-none focus:underline">
            Sign Up
          </Link>
        </div>
      </form>
    </div>
 );
}

export default Login;

