import React, { useEffect } from 'react';
const NODE_ENV = process.env.NODE_ENV;

// DRY this up
const pKey = (NODE_ENV === "test" || NODE_ENV === "development") ? "pk_test_JjV4gKL40gGvXmhYidNU3SQE" : "pk_live_B0HMcJdgsDWxhOq9N7hceFCt";

function ManagePage() {
  // Paste the stripe-pricing-table snippet in your React component
  // TODO: Do logic that allows subscription updates
  return (
    <>
    </>
  );
}

export default ManagePage;
