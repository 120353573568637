import './App.css';
import React, { useContext, useState } from 'react';
import { logout } from "./firebase.js";
import AuthContext from "./AuthContext.js";
import { useNavigate, Navigate } from "react-router-dom";

import ChatWidget from './pages/ChatWidget.js';
import Header from './components/Header.js';

const App = () => {
  const { user, updateUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    console.log("Inside handleLogout function.");
    try {
      await logout();
      updateUser(null);

    } catch(error) {
      console.log("There was an error logging out.");
      console.log(error.message);
      throw(error);
    };
    navigate("/login");
  };

  if (!user) { return (<Navigate replace to="/login" />) }
  const props = { handleLogout };

    
 return (
    <div className="min-h-screen flex flex-col">
    <Header handleLogout={handleLogout} />
    <div className="pt-16">
      <ChatWidget handleLogout={handleLogout} user={user} />
    </div>
  </div>
  );
};

export default App;

