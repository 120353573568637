// pages/Signup.js

import { useContext, useEffect, useState } from "react";
import AuthContext from "../AuthContext.js";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db, signUp } from "../firebase";
import { isFreeUser } from "../dbUtils.js";
import Signup from "../components/Signup.js";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, updateUser } = useContext(AuthContext);
  const [groupId, setGroupId] = useState("");
  const [error, setError] = useState("");
  const [freeUser, setFreeUser] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (freeUser) {
      console.log("Was free User");
      navigate("/")
    } 
  }, [freeUser, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check to see if user is already in database
    let freeUserResult;

    try{
      freeUserResult = await isFreeUser(email);
      setFreeUser(freeUserResult);
    } catch (error) {
      console.log(error.message)
      setError("Could not determine if you're a free user.");
    }
  
    try {
      // Create user account with email and password
      // This will be deleted if Stripe Subscription is not successfull
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;
      const userPayload = Object.assign({}, { role: "customer",
                                              display_name: user.email,
                                              email: user.email.toLowerCase(),
                                              freeUser: freeUserResult,
                                              groupId, });

      // Save user details to Firestore database
      await setDoc(doc(db, 'users', user.uid), userPayload);

      // Reset form fields and navigate to main app page
      setEmail('');
      setPassword('');
      setGroupId('');
      updateUser(userPayload);
    } catch (error) {
      console.log(error.message);
      setError("Could not create user!");
    }
    if (freeUserResult) {
      navigate("/");
      return null;
    } 
    navigate("/subscribe");
  };

  const props = {
    email,
    error,
    password,
    groupId,
    handleSubmit,
    setEmail,
    setError,
    setPassword,
    setGroupId,
  };

  return (
   <Signup {...props} /> 
  );
};

export default SignupPage;
