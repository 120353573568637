import * as React from 'react';
const NODE_ENV = process.env.NODE_ENV;

// DRY this up
const pKey = (NODE_ENV === "test" || NODE_ENV === "development") ? "pk_test_JjV4gKL40gGvXmhYidNU3SQE" : "pk_live_B0HMcJdgsDWxhOq9N7hceFCt";
const pricingTableId = (NODE_ENV === "test" || NODE_ENV === "development") ? "prctbl_1NPvQkI8mVz1aupbPTxXSlJ6" : "prctbl_1NPqi5I8mVz1aupbtjiE756T";

function SubscriptionPage() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <div style={{ display: 'flex' }}>
      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={pKey}
        style={{ flex: '1' }}
      >
      </stripe-pricing-table>
    </div>
  );
}

export default SubscriptionPage;
