import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { 
  addDoc,
  collection,
  connectFirestoreEmulator,
  getFirestore,
} from "firebase/firestore";
const credential = require("./morpheus-c074f-firebase-adminsdk-n5sah-f14c947375.json");

const firebaseConfig = {
  credential,
  apiKey: "AIzaSyBll8UPdrht4f4lcuzGh0IPIBwK_XHCJh0",
  authDomain: "morpheus-c074f.firebaseapp.com",
  projectId: "morpheus-c074f",
  storageBucket: "morpheus-c074f.appspot.com",
  messagingSenderId: "54510020157",
  appId: "1:54510020157:web:6aa9da179e7007eb1d6c54",
  measurementId: "G-RD8796DFW9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app);
const db = getFirestore(app);

// Check for test environment
if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
  console.log("Confirmed we are in a testing environment!!!");
  // Configure Firebase Auth emulator for local testing
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFirestoreEmulator(db, 'localhost', 8080);
}


const signUp = async (email, password, groupId) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      email: user.email,
    });
    return true
  } catch (error) {
    return {error: error.message}
  }
};

const login = async (email, password) => {
  let user;
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    user = userCredential.user;
  } catch (err) {
    console.log("There was an error logging in!");
    console.log(err.message);
    throw(err);
  }

  return user
};

const logout = async() => {
  try {
    await signOut(auth)
    return true
  } catch (error) {
    return false
  }
};


export { app, auth, db, getFirestore, signUp, login, logout };

