import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ handleLogout }) => {
  return (
    <header className="fixed top-0 left-0 right-0 bg-gray-800 text-white py-4 px-6 flex justify-between items-center">
      <nav>
        <ul className="flex space-x-4">
          <li>
            <Link to="/profile">Profile</Link>
          </li>
          <li>
            <button
              className="text-white hover:text-gray-300"
              onClick={handleLogout}
            >
              Logout
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
