// pages/LoginPage.js

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import AuthContext from "../AuthContext.js";
import { login, logout } from "../firebase";
import Login from "../components/Login.js";
import { checkSubscription, canHaveAccess, isFreeUser } from "../dbUtils.js";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, updateUser } = useContext(AuthContext);
  const [freeUser, setFreeUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Triggered useEffect")
    if (freeUser) {
      console.log("freeUser was present");
      navigate("/");
    } else if (user && !user.error) {
      console.log("Was NOT a freeUser but did have user")
      if (!canHaveAccess(user)) {
        navigate("/manage");
      }
      navigate("/");
    }
  }, [freeUser, user]);
  
  const handleLogin = async (e) => {
    e.preventDefault();
    let localUser;
    try {
      // Perform the login with Firebase authentication
      localUser = await login(email, password);
    } catch (err) {
      // Handle login error
      console.log("There was an error while logging in:");
      console.log("Let's stop here");
      console.log(err.message)
    }

    let freeUserCheckResult;
    try {
      freeUserCheckResult = await isFreeUser(localUser.email);
      setFreeUser(freeUserCheckResult);
      updateUser(localUser)
    } catch(err) {
      console.log("Could not check database. (freeUser)");
      console.log(err.message)
      throw new Error("Could not check freeUser in database.");
    }

    if (!freeUserCheckResult) {
      let subDetails;
      try {
        subDetails = await checkSubscription(localUser.email)
      } catch(err) {
        console.log("Could not contact stripe database to check subscription")
        console.log(err.message);
      }

      const { subStatus, cancel_at_period_end, current_period_end, planIsActive } = subDetails;

      if (!canHaveAccess(subDetails)) {
        // Load Profile with link to this users subscription
        // options directly from stripe.
        // TODO: update authcontext user to null so they are not logged
        navigate("/manage");
      } else {
        // TODO: update user with subscription active or not
        updateUser(Object.assign(localUser, {
          planIsActive,
          subStatus,
          cancel_at_period_end,
          current_period_end,
        }));
      }
    }
  };

  const getError = () => {
    const result = ((user && 'error' in user) ? user.error : null);
    const reason = (result) ? result.split(':')[1] : null;
    if (reason) console.log(reason);

    return (reason) ? 'Invalid Credentials or lapsed Subscription.' : null;
  }

  const showJSX = () => {
    const userError = getError(); // Fix this
    const props = {
      handleLogin,
      email,
      userError,
      password,
      setEmail,
      setPassword
    };

    return (<Login {...props} />);
  };
  

  return showJSX();
};

export default LoginPage;
