// components/ProtectedRoutes.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import App from '../App.js';
import { canHaveAccess } from "../dbUtils.js";

const ProtectedRoutes = () => {
  const { user } = useContext(AuthContext);
  
  if (user && !user.error) {
    if (!canHaveAccess(user)) {
      return (<Navigate to="/manage" replace />);
    }
    return (<App user={user} />);
  } else {
    console.log("Hit Protected Route without valid user.")
    return (<Navigate to="/login" replace />);
  }
};

export default ProtectedRoutes;
