// src/dbUtils.js

import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where
} from "firebase/firestore";

import { auth, db, signUp } from "./firebase.js";
import { stripe } from "./stripeUtils.js";

const checkSubscription = async (email) => {
  const usersCollection = collection(db, 'users');
  const q = query(usersCollection, where('email', '==', email));
  let querySnapshot;

  try {
    querySnapshot = await getDocs(q);
  } catch (err) {
    console.log("Failed to query database with email (checkSubscription)");
    console.log(err.message);
  }

  if (!querySnapshot.empty) {

    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();
    const { subscriptionId } = userData;

    try {
      const subscription = await stripe.subscriptions.retrieve(userData.subscriptionId);
      const {
        id,
        plan,
        status,
        cancel_at_period_end,
        current_period_end,
      } = subscription;
      return {
        planIsActive: plan.active,
        subStatus: status,
        cancel_at_period_end,
        current_period_end,
      };
    } catch(err) {
      console.log("Error happened when contacting stripe!!!")
      console.log(err.message)
      throw(err)
    }
  } else {
    throw("Could not find user with subscription. Sign Up!")
  }
};

const canHaveAccess = (details) => {
  const { subStatus, cancel_at_period_end, current_period_end } = details;
  const periodEndDate = new Date(current_period_end * 1000);
  const currentTimestamp = new Date();

  if (subStatus === 'canceled') {
    // This is for someone canceling but will have access until the end of the billing period
    if (cancel_at_period_end) { 
      if (periodEndDate <= currentTimestamp) {
        // The current billing period has already ended, so access is denied
        console.log('Access denied');
        return false;
      } else {
        // The current billing period is not yet over, so access is still allowed
        console.log('Current billing period is not over yet, access is still allowed');
        console.log('Access allowed');
        return true;
      }
    } else {
      // The subscription was manually canceled, so access is denied immediately
      console.log('Access denied Manually');
      return false;
    }
  } else {
    // The subscription is active, so access is allowed
    console.log('status is still active')
    console.log('Access allowed');
    return true;
  }
};

// TODO: finish moving this here
const isFreeUser = async (email) => {
  const freeUsersCollection = collection(db, 'freeUsers');
  const q = query(freeUsersCollection, where('email', '==', email));
  let querySnapshot;

  try {
    querySnapshot = await getDocs(q);
    console.log("Query snapshot returned.")
  } catch(e) {
    console.log("Have error");
    console.log(e.message);
  }
  
  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();
    console.log('Found free user!');
    return true;
  } else {
    console.log('User document not found');
    return false;
  }
};

export { checkSubscription, canHaveAccess, isFreeUser };
