import { Link } from "react-router-dom";

const Signup = (props) => {
  const {
    email,
    error,
    groupId,
    password,
    handleSubmit,
    setEmail,
    setPassword,
    setGroupId,
  } = props;

  return (
    <>
      <h2 className="text-3xl font-bold mb-4">Sign Up</h2>
      <div>
        {error && <div className="text-red-500">{error}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            name="email"
            value={email}
            placeholder="Your Email"
            required
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="password"
            name="password"
            value={password}
            placeholder="Your Password"
            required
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
              type="text"
              name="groupId"
              value={groupId}
              placeholder="Group ID"
              required
              onChange={(e) => setGroupId(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600">
              Submit
            </button>
        </form>
        <p className="mt-4">
          Already registered? <Link to="/login" className="text-blue-500">Login</Link>
        </p>
      </div>
    </>
  );
};

export default Signup;
