// pages/ChatWidget.js
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext.js";
import CodyBotWidget from '../components/CodyBotWidget.js';
import { canHaveAccess } from "../dbUtils.js";

const ChatWidget = (props) => {

  return (
    <div className="relative h-[calc(100vh-4rem)] z-10 h-screen overflow-y-auto">
      <CodyBotWidget />
    </div>
  );
};

export default ChatWidget;
