import React from 'react';

const CodyBotWidget = (props) => {
  return (
    <div className="h-full">
      <iframe
        src="https://getcody.ai/widget/9981a8c2-b935-4a41-8fa4-34fc7ac5e11b"
        className="w-full h-full border-0"
        name="Morpheus"
        scrolling="no"
        frameBorder="0"
        allowFullScreen
        style={{ maxHeight: '100vh', width: '100vw' }}
      ></iframe>
    </div>
  );
};

export default CodyBotWidget;
