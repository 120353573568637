import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import AuthProvider from './components/AuthProvider.js';
import reportWebVitals from './reportWebVitals';
import LoginPage from './pages/LoginPage.js';
import ManagePage from './pages/ManagePage.js';
import SignupPage from './pages/SignupPage.js';
import SubscriptionPage from './pages/SubscriptionPage.js';
import ProtectedRoutes from './components/ProtectedRoutes.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
// ChatWidget may be better in the App component
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<ProtectedRoutes />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/manage" element={<ManagePage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/subscribe" element={<SubscriptionPage />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
